import { CACHE_TTL, cache } from '#/services/cache';
import { differenceInDays } from 'date-fns';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import ErrorReporter from '../lib/ErrorReporter';
import JucyAPI from '../lib/JucyAPI.js';
import Quote from '../lib/api/model/Quote';
import { V2AccountDetails } from '#/lib/api/model/V2AccountDetails';
import { resolveError } from '#/lib/resolveError';
import { FleetType } from '#/services';
import { queryClient } from '#/layouts/ClientOnlyObeLayout/lib/init';

class AccountDetailsStore {
    state: 'loading' | 'pending' | 'error' | 'done' = 'pending';
    message = '';
    error?: Error;
    accountDetails?: V2AccountDetails = undefined;

    constructor() {
        makeAutoObservable(this);
        reaction(
            () => this.state,
            (data) => {
                if (data === 'error') {
                    ErrorReporter.reportError({
                        error: this.error,
                        tags: { store: 'account-detail' },
                    });
                } else {
                    this.error = undefined;
                    this.message = '';
                }
            }
        );
    }

    async getAccountDetails() {
        this.state = 'loading';
        this.message = '';
        this.accountDetails = undefined;
        const cacheResult = await cache.get<V2AccountDetails>('account-details');
        if (cacheResult) {
            this.accountDetails = cacheResult;
            this.state = 'done';
        } else {
            try {
                const accountId = JucyAPI.getAccountKey();
                const response = await queryClient.fetchQuery({
                    queryKey: ['account-details', accountId],
                    queryFn: async () => await JucyAPI.getAccountDetails(),
                });
                runInAction(() => {
                    if (response.ResponseType !== JucyAPI.responseTypes.success) {
                        this.message = response.Message;
                        this.state = 'error';
                    } else {
                        this.state = 'done';
                        this.accountDetails = response.Data as V2AccountDetails;
                        cache.set('account-details', this.accountDetails, CACHE_TTL.FIVE_MIN);
                    }
                });
            } catch (e) {
                resolveError(e).then(({ error, message }) => {
                    runInAction(() => {
                        this.state = 'error';
                        this.error = error;
                        this.message = message;
                    });
                });
            }
        }

        return this.accountDetails;
    }

    resolveCannotBeCancelled(activeQuote: Quote): boolean {
        const daysTillPickup = differenceInDays(activeQuote.PickUpDateTime, new Date());
        const cancellationMinimum = this.accountDetails?.CancellationMinimum ? this.accountDetails.CancellationMinimum : 21;
        return this.accountDetails ? daysTillPickup < cancellationMinimum : true;
    }

    getFleetTypeLeadTime(fleetType?: FleetType) {
        let leadTime: number;
        try {
            const accLeadTime = fleetType ? this.accountDetails?.LeadTimes.find((l) => l.FleetTypeId === fleetType.id) : null;
            leadTime = accLeadTime?.LeadTimeHrs || 2;
        } catch (e) {
            leadTime = 2;
        }
        return leadTime;
    }
}

export default new AccountDetailsStore();
