import { useEffect, useState } from 'react';
import AccountDetailsStore from '#/store/AccountDetailsStore';
import { V2AccountDetails } from '#/lib/api/model/V2AccountDetails';

export const useGetAccountDetails = () => {
    const [accountDetails, setAccountDetails] = useState<V2AccountDetails | undefined>();
    useEffect(() => {
        AccountDetailsStore.getAccountDetails().then((details) => {
            setAccountDetails(details);
        });
    }, []);
    return accountDetails;
};
