import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import Product from '../../../../lib/api/model/Product';
import ProductGroup from '../../../../lib/api/model/ProductGroup';
import slugify from '../../../../lib/slugify';
import TranslationStore from '../../../../store/TranslationStore';
import { formatCurrency } from '../../../CurrencyFormatter/CurrencyFormatter';
import { JucyMuiAccordion, JucyMuiAccordionDetails, JucyMuiAccordionSummary } from '../../../JucyMuiAccordion';
import InfoText from '../../../Text/InfoText';
import ExtrasRow from './ExtrasRow';
import { ObeProductLine } from '#/types/ObeProductLine';

export interface ExtrasGroupProps {
    productGroup: ProductGroup<Product>;
    selectedItems: Product[];
    bundledItems?: ObeProductLine[];
    activeCode?: string;
    onChange: (item: Product, delta: number) => void;
    isSelecting?: boolean;
    readonly?: boolean;
    isOpen: boolean;
}

const findCO2OProduct = (productGroup: ProductGroup<Product>) => {
    const product = productGroup.Items.find((product) => product.ProductCode === 'CO2O');
    const price = product?.Price;
    return price ? formatCurrency(price) : null;
};

const getDogInVehiclePrice = (productGroup: ProductGroup<Product>) => {
    const product = productGroup.Items.find((product) => product.ProductCode === 'DOGF');
    const price = product?.Price;
    return price ? formatCurrency(price) : null;
}

const ExtrasGroup: React.FC<ExtrasGroupProps> = ({ productGroup, selectedItems, bundledItems, isSelecting, activeCode, onChange, isOpen: isOpenPassed, readonly }) => {
    const { t } = useTranslation(TranslationStore.currentNamespace);
    const groupName = productGroup.WebCategory || productGroup.Group;
    const groupKey = resolveProductGroupKey(groupName);

    const carbonOffsetPrice = findCO2OProduct(productGroup);
    const dogInVehiclePrice = getDogInVehiclePrice(productGroup);
    const info = t(groupKey, { carbonOffsetPrice, dogInVehiclePrice });

    const [isOpen, setIsOpen] = useState(isOpenPassed);
    const toggleAccordion = useCallback(() => setIsOpen(!isOpen), [isOpen]);

    return (
        <>
            <JucyMuiAccordion expanded={isOpen} elevation={0} onChange={toggleAccordion}>
                <JucyMuiAccordionSummary aria-controls={`${slugify(groupName)}-content`} id={`${slugify(groupName)}-header`}>
                    <Typography fontWeight={700} fontSize={14}>
                        {groupName}
                    </Typography>
                </JucyMuiAccordionSummary>
                <JucyMuiAccordionDetails>
                    {info && info !== groupKey ? <InfoText sx={{ marginBottom: 2 }} info={info} /> : null}
                    <div>
                        {productGroup.Items.filter((i) => i.ProductCode).map((extra) => (
                            <ExtrasRow
                                readonly={readonly}
                                key={extra.ProductCode}
                                isSelecting={isSelecting}
                                active={activeCode === extra.ProductCode}
                                onChange={onChange}
                                item={extra}
                                selectedItems={selectedItems}
                                bundledItems={bundledItems}
                            />
                        ))}
                    </div>
                </JucyMuiAccordionDetails>
            </JucyMuiAccordion>
        </>
    );
};

const resolveProductGroupKey = (groupName: string) => {
    let groupKey = groupName.replace(' ', '_').replace(/\W/g, '').toLowerCase();
    if (groupKey.includes('ferry')) {
        groupKey = 'ferry_tickets';
    } else if (groupKey.includes('carbon')) {
        groupKey = 'carbon_offset';
    }

    return `product.group.${groupKey}`;
};

export default ExtrasGroup;
